const dropdownToggleSel = 'button[data-action=dropdown-toggle]'
const dropdownCloseSel = 'button[data-action=close-dropdown]'

const dropdown = document.querySelector('.dropdown')

const dropdownToggleBtns = document.querySelectorAll<HTMLButtonElement>('button[data-action=dropdown-toggle]')
const dropdownMenuCloseBtns = document.querySelectorAll<HTMLButtonElement>('button[data-action=close-dropdown]')

document.body.addEventListener('click', dropdownClickHandler)

function dropdownClickHandler(e: MouseEvent) {
  const target = e.target as HTMLElement
  const selectBlocks = document.querySelectorAll<HTMLElement>('.dropdown')

  if(selectBlocks.length <= 0) return

  selectBlocks.forEach(function (block) {
    if(block.contains(target)){
      const toggle = block.querySelector(dropdownToggleSel) as HTMLElement
      toggle.nextElementSibling?.classList.toggle('dropdown-menu--opened')
    } else if (block.contains(target)) {
      const close = block.querySelector(dropdownCloseSel) as HTMLElement
      const menu = close.closest('.dropdown-menu') as HTMLElement
      menu.classList.remove('dropdown-menu--opened')
    }
  })
}

document.body.addEventListener('click', outsideDropdownClickHandler)

function outsideDropdownClickHandler(e: MouseEvent) {
  const target = e.target as HTMLElement
  const selectBlocks = document.querySelectorAll<HTMLElement>('.dropdown')

  selectBlocks.forEach(function (block) {
    if(block && 
      !block.contains(target) && 
      !block.closest('.dropdown-menu')
    ){
      const dropdownMenu = block.querySelector('.dropdown-menu') as HTMLElement
      dropdownMenu.classList.remove('dropdown-menu--opened')
    }  
  })
}

// dropdownToggleBtns.forEach(function (btn) {
//   btn.addEventListener('click', function () {
//     btn.nextElementSibling?.classList.toggle('dropdown-menu--opened')

//     // const arrow = btn.querySelector('.dropdown__icon.dropdown__icon--arrow') as HTMLElement
//     // arrow.classList.toggle('rotate-90')
//   })
// })

// dropdownMenuCloseBtns.length > 0 && dropdownMenuCloseBtns.forEach(
//   function (closebtn) {
//     closebtn.addEventListener('click', function () {
//       const menu = closebtn.closest('.dropdown-menu') as HTMLElement
//       menu.classList.remove('dropdown-menu--opened')
//     })
//   }
// )