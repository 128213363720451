import { delegateEvent } from 'scripts/delegateEvent'

const selectOptionInputSel = 'input[data-select-option]'

const openSelectBtnSel = '.select .input'
const closeSelectBtnSel = 'button[data-action=close-select]'
const search = '.select .select-search'

delegateEvent('click', document.body, openSelectBtnSel, openSelectClickHandler)

delegateEvent('click', document.body, closeSelectBtnSel, closeSelectClickHandler)
delegateEvent('change', document.body, selectOptionInputSel, inputOptionChangeHandler)

delegateEvent('input', document.body, search, input)

function input(e: Event) {
  const target = e.target as HTMLInputElement;
  const ul = target.parentNode?.parentNode?.querySelector('.select-menu__list');

  setTimeout(() => {
    let items = ul?.childNodes;
    items?.forEach((item) => {
      if (item instanceof HTMLElement) { 
        const textElement = item.querySelector('.radio__label');
        if (textElement instanceof HTMLElement) { 
          const text = textElement.textContent; 
          if (!(text?.toLocaleLowerCase().includes(target.value.toLocaleLowerCase()))) {
            const parentElement = textElement.parentElement;
            if (parentElement && parentElement.parentElement) {
              parentElement.parentElement.style.display = 'none';
            }
          } else {
            const parentElement = textElement.parentElement;
            if (parentElement && parentElement.parentElement) {
              parentElement.parentElement.style.display = 'flex';
              const selectMenuItem = parentElement.parentElement.querySelector('.flex-row');
              if (selectMenuItem instanceof HTMLElement) {
                selectMenuItem.style.display = 'flex';
                selectMenuItem.style.justifyContent = 'space-between';
                selectMenuItem.style.width = '100%';
              }
            }
          }
        }
      }
    });
  }, 1000);
}

// Handlers
function openSelectClickHandler(e: Event) {
  const target = e.target as HTMLElement
  const targetElem = target.closest(openSelectBtnSel) as HTMLElement

  const inputBlock = targetElem?.closest('.input')
  const selectMenu = inputBlock?.nextElementSibling as HTMLElement

  if (targetElem.parentElement?.classList.contains('select-disabled')) return

  if (selectMenu?.classList.contains('select-menu--opened')) {
    hideSelect(selectMenu)
    selectMenu.classList.remove('select-menu--opened')
  }

  selectMenu?.classList.add('select-menu--opened')
  selectMenu?.classList.add('fade-in')
  const itemList = selectMenu?.querySelector('ul')

  const openEvent = new Event("select_open")
  targetElem.parentElement?.dispatchEvent(openEvent)
}

function closeSelectClickHandler(e: Event) {
  const target = e.target as HTMLElement

  const selectBlock = target.closest('.select')
  const selectMenu = selectBlock?.querySelector('.select-menu') as HTMLElement
  hideSelect(selectMenu)
}

const hideSelect = (select: HTMLElement) => {
  select.classList.replace('fade-in', 'fade-out')

  setTimeout(() => {
    select.classList.remove('select-menu--opened')
    select.classList.remove('fade-out')
  }, 300)
}
//Выбор+
function inputOptionChangeHandler(e: any) {
  const target = e.target as HTMLInputElement
  const inputOption = target.closest(selectOptionInputSel) as HTMLInputElement

  const selectBlock = inputOption.closest('.select') as HTMLElement
  const inputSelectValue = selectBlock.querySelector('input[data-select=select-value]') as HTMLInputElement
  const inputSelectHidden = selectBlock.querySelector('input[data-select]') as HTMLInputElement

  const labelText = target.parentElement?.querySelector(".radio__label")?.textContent
  const selectMenu = selectBlock.querySelector('.select-menu') as HTMLElement
  
  if(!(target.type === 'checkbox')) {
    inputSelectValue.value = labelText as string
    inputSelectHidden.value = `${labelText}`
    inputOption.value = `${inputOption.dataset.selectOption}`
  }

  if(target.type === 'checkbox') {
    const checkboxInput = selectMenu?.querySelectorAll('.checkbox__input')
    
    const labelText = target.parentElement?.querySelector(".checkbox__label")?.textContent
    
    if ((target as HTMLInputElement).checked) {
      if(inputSelectValue.value.length === 0) {
        inputSelectValue.value += labelText as string
      } else inputSelectValue.value += `, ${labelText as string}`
    } else {
      inputSelectValue.value = ''

      checkboxInput.forEach(item => {
        if (item instanceof HTMLInputElement && item.checked) {
          const labelText = item.parentElement?.querySelector(".checkbox__label")?.textContent
          if(inputSelectValue.value.length === 0) {
            inputSelectValue.value += labelText as string
          } else inputSelectValue.value += `, ${labelText as string}`
        } 
      });
    }
    
  }
  
  if (!(target.type === 'checkbox')) {
    setTimeout(() => {
      hideSelect(selectMenu)
      selectBlock.dispatchEvent(new CustomEvent("item_selected"))
    }, 200)
  }
}

// ----- Outside Click Handle -----
document.body.addEventListener('click', outsideSelectClickHandler)

function outsideSelectClickHandler(e: MouseEvent) {
  const target = e.target as HTMLElement
  const selectBlocks = document.querySelectorAll<HTMLElement>('.select')

  selectBlocks.forEach(function (block) {
    if (block && !block.contains(target) && !block.closest('.dropdown-menu')) {
      const selectMenu = block.querySelector('.select-menu') as HTMLElement
      hideSelect(selectMenu)
    }
  })
}
