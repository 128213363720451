export function delegateEvent(
  eventType: any,
  delegateElement: HTMLElement | Document,
  targetElementSelector: string,
  callback: (ev: any, closest?: HTMLElement) => any,
  isCapture: boolean = false
) {
  delegateElement.addEventListener(eventType, function (this: HTMLElement, event) {
    const target = event.target as HTMLElement
    //const targetElem = this.querySelector(targetElementSelector)
    if (target &&
        target.matches &&
        target.matches(`${targetElementSelector}, ${targetElementSelector} *`)
    ) {
      const target = event.target as HTMLElement
      const closest = target.closest(targetElementSelector) as HTMLElement
      callback(event, closest)
    }
  },
  isCapture
  )
}
