const textarea_list = document.querySelectorAll(".textarea-edit") 

textarea_list.forEach(x => {
  const textarea = (x.querySelector("textarea") as unknown) as HTMLInputElement
  const subplaceholder = x.querySelector(".subplaceholder") as HTMLElement
  textarea.addEventListener("input", () => {    
    if(subplaceholder === null) return 
    if(textarea.value === ""){
      subplaceholder.classList.remove("subplaceholder_hide")
    }else{
      subplaceholder.classList.add("subplaceholder_hide")
    }
  })
})
