import "components/popups/selectCountry"

const openModalBtn = document.querySelector<HTMLButtonElement>('.header__login')
const modal = document.querySelector('.popup-login')

openModalBtn?.addEventListener('click', openModel)

function openModel() {
  modal?.classList.add('opened')
}
