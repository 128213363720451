const popupActiveClass = 'simple-popup-active'
const start_z_index = 26
const closeUnusedPopups = (currIndex: number) => {
  document.querySelectorAll('.simple-popup').forEach((anotherPopup, index) => {
    if (index === currIndex) return

    if ('closeOnButton' in (anotherPopup as HTMLElement).dataset) return
    hideSimplePopup(anotherPopup as HTMLElement)
  })
}

const initSimplePopup = () => {
  document.addEventListener('click', () => {
    document.querySelectorAll('.simple-popup').forEach((popup, index) => {
      if ('closeOnButton' in (popup as HTMLElement).dataset) return
      if (JSON.parse((popup as HTMLElement).dataset.onMouse as string)) return
      hideSimplePopup(popup as HTMLElement)
    })
  })

  document.querySelectorAll('.simple-popup').forEach((popup, currIndex) => {
    ;(popup as HTMLElement).dataset.onMouse = 'false'

    popup.querySelector('.simple-popup__close')?.addEventListener('click', () => {
      hideSimplePopup(popup as HTMLElement)
    })

    popup.querySelector('.simple-popup-trigger')?.addEventListener('click', () => {
      if (popup?.classList.contains(popupActiveClass)) {
        hideSimplePopup(popup as HTMLElement)
      } else {
        popup?.classList.add(popupActiveClass)
        closeUnusedPopups(currIndex)
      }
    })

    popup.addEventListener('mouseleave', () => {
      ;(popup as HTMLElement).dataset.onMouse = 'false'
    })
    popup.addEventListener('mouseover', () => {
      ;(popup as HTMLElement).dataset.onMouse = 'true'
    })
  })
}

const hideSimplePopup = (popup: HTMLElement) => {
  const popupBody = popup.querySelector('.simple-popup-body') as HTMLElement
  popupBody.classList.add('fade-out')
  setTimeout(() => {
    popup.classList.remove(popupActiveClass)
    popupBody.classList.remove('fade-out')
  }, 300)
}

export { hideSimplePopup, initSimplePopup }
