import { disableScroll, enableScroll } from "global/features/scroll"

const menu = document.querySelector('.menu[data-menu=main]') as HTMLElement

const closeBtns = document.querySelectorAll<HTMLButtonElement>('button[data-action=menu-close]')
const openBtns = document.querySelectorAll<HTMLButtonElement>('[data-action=menu-open]')

function hideMenu() {
  if(menu.classList.contains('--opened')) {
    menu.classList.replace('slide-in', 'slide-out')
    
    enableScroll()
    setTimeout(function() {
      menu.classList.remove('--opened')
      menu.classList.replace('slide-out', 'slide-in')
    }, 502)
  }
}

closeBtns.forEach(function (closebtn) {
  closebtn.addEventListener('click', function () {
    hideMenu()
  })
})

openBtns.forEach(function (openbtn) {
  openbtn.addEventListener('click', function () {
    menu.classList.add('--opened')
    disableScroll()
  })
})