import { disableScroll, enableScroll } from 'features/scroll'

const footerMobile = document.querySelector('.footer__mobile') as HTMLElement
const stickyFooter = footerMobile.querySelector('.footer__mobile__sticky-footer')
const searchResult = document.querySelector('.search-result') as HTMLElement

document.querySelector('.confirm-search-opener')?.addEventListener('click', () => {
  if (searchResult.classList.contains('--opened')) return
  stickyFooter?.classList.add('footer__mobile__sticky-footer_search-shown')
})

stickyFooter?.querySelector('.search')?.addEventListener('click', () => {
  stickyFooter?.classList.remove('footer__mobile__sticky-footer_search-shown')
  ;(searchResult.querySelector('.search-input__field') as HTMLInputElement).focus()
})


