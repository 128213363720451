import { disableScroll, enableScroll } from 'global/features/scroll'
import Swiper from 'swiper'

const SEARCH_INPUT_SELECTOR = '.search-input input[data-input=search]'
const searchInputs = document.querySelectorAll<HTMLInputElement>(SEARCH_INPUT_SELECTOR)

const SEARCH_RES_BLOCK_SELECTOR = '.search-result[data-search=global]'
const searchResultBlock = document.querySelector(SEARCH_RES_BLOCK_SELECTOR) as HTMLElement

// Main Menu
const menu = document.querySelector('.menu[data-menu=main]') as HTMLElement

searchInputs.forEach(function (input) {
  input.addEventListener('click', function () {
    searchResultBlock.classList.add('--opened')
    disableScroll()
    menu.classList.remove('--opened')
  })
})

let onMouse = null as any

searchResultBlock.addEventListener('mouseenter', () => {
  onMouse = true
})

searchResultBlock.addEventListener('mouseleave', () => {
  onMouse = false
})

document.body.addEventListener('keydown', escKeyHandler)

window.addEventListener('click', () => {
  if (onMouse || onMouse === null) return
  searchResultBlock.classList.replace('slide-in', 'slide-out')

  setTimeout(function () {
    searchResultBlock.classList.remove('--opened')
    searchResultBlock.classList.replace('slide-out', 'slide-in')
    enableScroll()
    onMouse = null
  }, 502)
})

const closeBtnSearch = document.querySelector('.menu-close-search')

closeBtnSearch?.addEventListener('click', () => {
  searchResultBlock.classList.replace('slide-in', 'slide-out')

  setTimeout(function () {
    searchResultBlock.classList.remove('--opened')
    searchResultBlock.classList.replace('slide-out', 'slide-in')
    enableScroll()
    onMouse = null
  }, 502)
})

function showHandler() {
  if (searchResultBlock === null) return
  const buttonList = document.querySelectorAll('.search__opener')
  buttonList.forEach((button) => {
    button?.addEventListener('click', () => {
      if (searchResultBlock.classList.contains('--opened')) {
        searchResultBlock.classList.replace('slide-in', 'slide-out')

        setTimeout(function () {
          searchResultBlock.classList.remove('--opened')
          searchResultBlock.classList.replace('slide-out', 'slide-in')
          enableScroll()
          onMouse = null
        }, 502)
      } else {
        searchResultBlock.classList.add('--opened')
        disableScroll()
      }
    })
  })
}

function escKeyHandler(e: KeyboardEvent) {
  if (e.key !== 'Escape') return
  searchResultBlock.classList.remove('--opened')
}

new Swiper('.swiper-search-result--mini', {
  spaceBetween: 12,
  direction: 'horizontal',
  slidesPerView: 'auto',
  breakpoints: {
    1200: {
      slidesPerView: 3,
    },
  },
})

new Swiper('.swiper-search-result--big', {
  spaceBetween: 12,
  direction: 'horizontal',
  slidesPerView: 1,
  breakpoints: {
    1200: {
      slidesPerView: 2,
    },
  },
})

showHandler()
