const initInputCheckbox = () => {
  document.querySelectorAll('.select-item input').forEach((input) => {
    const name = input.getAttribute('name') as string
    const value = input.getAttribute('value') as string

    if ((input as HTMLInputElement).checked) {
      const select = input.parentElement 
      const labelText = select?.querySelector('.select-item__label')?.textContent
      let selectValue = select?.parentElement?.parentElement?.parentElement?.parentElement as HTMLElement | null;
      
      const checkbox = input.parentElement?.parentElement;
      const divSelect = checkbox?.querySelector('.checkbox')

      divSelect?.classList.add('select-input_checked')

      if (selectValue) {
        selectValue = selectValue.querySelector('.input__field input');
        if (selectValue instanceof HTMLInputElement) {
          
          if (selectValue.value.length === 0) {
            selectValue.value += labelText
          } else selectValue.value += `, ${labelText}`
        }
      }
    }
    

    // input.removeAttribute('name')
    // input.removeAttribute('value')

    // input.setAttribute('data-name', name)
    // input.setAttribute('data-name', value)
  })
}

const initCheckbox = (e: any) => {
  const checkbox = e.target.closest('div.checkbox')

  const checkboxInput = checkbox.querySelector('.checkbox__input')
  const checkboxName = checkboxInput?.getAttribute('name')

  const checkedSelector = `.select-input_checked .checkbox__input[name=${checkboxName}] `

  if (checkbox.classList.contains('select-input_checked')) {
    // checkboxInput?.removeAttribute('name')
    checkbox.classList.remove('select-input_checked')
  } else {
    // checkboxInput?.setAttribute('name', checkboxName as string)
    checkbox.classList.add('select-input_checked')
  }
  // if (document.querySelectorAll(checkedSelector).length > 1) {
  //   document.querySelectorAll<HTMLElement>('.checkbox').forEach((element) => {
  //     const input = element.querySelector(`.checkbox__input[data-name=${checkboxName}]`)
  //     if (input === null) return

  //     input.removeAttribute('name')
  //   })
  // } else if (document.querySelectorAll(checkedSelector).length === 1) {
  //   document.querySelector(checkedSelector)?.setAttribute('name', checkboxName as string)
  // }
  if (document.querySelectorAll(checkedSelector).length === 0) {
    document.dispatchEvent(new CustomEvent('all_unchecked', { detail: { el: checkbox } }))
  }
}

const initRadio = (radio: HTMLElement) => {
  const radioName = radio.querySelector('.select-item__input')?.getAttribute('data-name')
  const radioValue = radio.querySelector('.select-item__input')?.getAttribute('data-value')
  const checkedSelector = `.select-item__input[data-name=${radioName}] `
  const radioIndex = parseInt((radio.querySelector('input') as any).dataset.selectOption as string)
  console.log(radioIndex)

  if (radio.classList.contains('select-input_checked')) return

  document.querySelectorAll('.radio').forEach((anotherRadio, index) => {
    if (anotherRadio.querySelector(checkedSelector) === null) return

    if (anotherRadio === radio) {
      anotherRadio.classList.add('select-input_checked')
      anotherRadio.querySelector(checkedSelector)?.setAttribute('name', radioName as string)
      anotherRadio.querySelector(checkedSelector)?.setAttribute('value', radioValue as string)

      document.dispatchEvent(new CustomEvent('radio_checked', { detail: { radio: anotherRadio } }))
    } else {
      anotherRadio.classList.remove('select-input_checked')
      anotherRadio.querySelector(checkedSelector)?.removeAttribute('value')
      anotherRadio.querySelector(checkedSelector)?.removeAttribute('name')
    }
  })
}

export { initCheckbox, initRadio, initInputCheckbox }
