import "src/global/features/popup"
import { openPopup } from "src/global/features/popup"

document.querySelectorAll(".privacy-policy__trigger").forEach(trigger => {
  trigger.addEventListener("click", () => {
    const popup = document.querySelector('[data-popup="policy"]') as HTMLElement
    const midOfScreen = document.documentElement.clientWidth / 2
    let direction = "opened-right"
    if(trigger.getBoundingClientRect().x > midOfScreen){
      direction = "opened-left"
    }
    openPopup("policy", direction)
    popup.addEventListener("closed", () => {
    })
    popup.addEventListener("animationend", (e) => {      
      popup.classList.remove(direction)
    })
  })
})

document.querySelectorAll(".rule-policy__trigger").forEach(trigger => {
  trigger.addEventListener("click", () => {
    const popup = document.querySelector('[data-popup="rule-use"]') as HTMLElement
    const midOfScreen = document.documentElement.clientWidth / 2
    let direction = "opened-right"
    if(trigger.getBoundingClientRect().x > midOfScreen){
      direction = "opened-left"
    }
    openPopup("rule-use", direction)
    popup.addEventListener("closed", () => {
    })
    popup.addEventListener("animationend", (e) => {      
      popup.classList.remove(direction)
    })
  })
})