import { Swiper } from 'swiper'

const seminarSlides = new Swiper('.seminar__swiper', {
  direction: 'horizontal',
  breakpoints: {
    800: { spaceBetween: 26},
    1200: {slidesPerView: 2, spaceBetween: 40}
  },
  slidesPerView: 1,
  spaceBetween: 12,
})