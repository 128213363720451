const voteAlerts = document.querySelectorAll('.voteAlerts')

function showAlert(alertClass: string) {
  const alertToShow = Array.from(voteAlerts).find((alert) => alert.classList.contains(alertClass)) as HTMLElement

  if (alertToShow) {
    hideOtherAlerts(alertToShow)
    alertToShow.classList.add('show')

    setTimeout(() => {
      alertToShow.classList.remove('show')
    }, 5000)
  }
}

function hideOtherAlerts(currentAlert: HTMLElement) {
  voteAlerts.forEach((alert) => {
    if (alert !== currentAlert) {
      alert.classList.remove('show')
    }
  })
}

function hideAlert(alertClass: string) {
  const alertToHide = Array.from(voteAlerts).find((alert) => alert.classList.contains(alertClass))

  if (alertToHide) {
    alertToHide.classList.remove('show')
  }
}

// Пример использования
showAlert('voteAlerts-error');
showAlert('voteAlerts-success');

