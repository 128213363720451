import 'features/popup'
import 'features/popup/simplePopup'
import 'features/forms'

import 'layout/general/header'
import 'layout/general/footer'
import 'layout/general/header/menu'

import 'ui/collapse'
import 'ui/dropdown'
import 'ui/select'
import 'ui/input'
import 'ui/input/checkboxes'
import 'ui/faqAccordion'
import 'ui/geographicList'
import 'ui/voteAlerts'

import 'pages/editProfile/UI/textarea'
import 'components/Search'
import 'components/seminars'
import 'components/popups/privacyPolicy'
import { initSimplePopup } from 'features/popup/simplePopup'
import { initInputCheckbox } from 'ui/input/checkboxes'
import 'components/preloader'

initInputCheckbox()
initSimplePopup()
