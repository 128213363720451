import Datepicker from 'vanillajs-datepicker/Datepicker'
import DateRangePicker from 'vanillajs-datepicker/DateRangePicker'
import { DatepickerOptions } from 'vanillajs-datepicker/Datepicker'

import { createMutationObserver } from 'features/mutationObserver'
import { initCheckbox, initRadio } from './checkboxes'
import { delegateEvent } from 'scripts/delegateEvent'

const datepickerSel = 'input[data-input=date]'
const datepickerRangeSel = '[data-input=range]'

const datepickerInputs = document.querySelectorAll<HTMLInputElement>(datepickerSel)
const datepickerRanges = document.querySelectorAll<HTMLInputElement>(datepickerRangeSel)

const cusru = {
  ru: {
    days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    daysShort: ['Вск', 'Пнд', 'Втр', 'Срд', 'Чтв', 'Птн', 'Суб'],
    daysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    today: 'Сегодня',
    clear: 'Очистить',
    format: 'dd.mm.yyyy',
    weekStart: 1,
    monthsTitle: 'Месяцы',
  },
}

Object.assign(Datepicker.locales, cusru)

const datePickerOptions: DatepickerOptions = {
  prevArrow: '',
  nextArrow: '',
  language: 'ru',
  autohide: true,
  todayHighlight: true,
  orientation: 'auto left',
}

datepickerInputs.length > 0 &&
  datepickerInputs.forEach(function (input) {
    const datepicker = new Datepicker(input, datePickerOptions)
  })

datepickerRanges.length > 0 &&
  datepickerRanges.forEach(function (elem) {
    if (elem instanceof HTMLElement) {
      const start = elem.querySelector('input[date-range-start]') as HTMLElement
      const end = elem.querySelector('input[date-range-end]') as HTMLElement
      if (start === null && end === null) return
      const dateRangePicker = new DateRangePicker(elem, {
        language: 'ru',
        inputs: [start, end],

        ...datePickerOptions,
      } as any)
    }
  })

createMutationObserver(document.body, onDPMutate)

createMutationObserver(document.body, onDPRangeMutate)

function onDPMutate(mutation: MutationRecord) {
  createDatePicker(mutation.addedNodes as NodeListOf<HTMLElement>)
}

function onDPRangeMutate(mutation: MutationRecord) {
  createDateRangePicker(mutation.addedNodes as NodeListOf<HTMLElement>)
}

function createDatePicker(elems: HTMLElement[] | NodeListOf<HTMLElement>) {
  if (datepickerInputs.length <= 0) return

  elems.length > 0 &&
    elems.forEach(function (elem) {
      if (elem instanceof HTMLElement) {
        const inputs = elem.querySelectorAll<HTMLInputElement>(datepickerSel)

        inputs.length > 0 &&
          inputs.forEach(function (input) {
            const datepicker = new Datepicker(input, datePickerOptions)
          })
      }
    })
}

function createDateRangePicker(elems: HTMLElement[] | NodeListOf<HTMLElement>) {
  if (datepickerRanges.length <= 0) return

  elems.length > 0 &&
    elems.forEach(function (elem) {
      if (elem instanceof HTMLElement) {
        const start = elem.querySelector('input[date-range-start]') as HTMLElement
        const end = elem.querySelector('input[date-range-end]') as HTMLElement
        if (start === null && end === null) return
        const dateRangePicker = new DateRangePicker(elem, {
          language: 'ru',
          inputs: [start, end],
          ...datePickerOptions,
        } as any)
      }
    })
}
delegateEvent('click', document, '.checkbox label', initCheckbox)
delegateEvent('click', document, '.radio label', (e) => {  
  initRadio((e.target as HTMLElement).closest('.radio') as HTMLElement)
})