const collapseList = document.querySelectorAll('.collapse')

collapseList.forEach((collapse) => {
  const header = collapse.querySelector('.collapse__header') as HTMLElement
  const body = collapse.querySelector('.collapse__body') as HTMLElement
  const bodyWrapperHeight = collapse.querySelector('.collapse__body__wrapper')?.getBoundingClientRect().height
  const originalCloseBtn = collapse.querySelector('.faq-accordion__close-btn') as HTMLElement
  const imgCross = document.createElement('img')
  imgCross.src =
    'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none"%3E%3Cpath d="M20 8L8 20M8 8L20 20" stroke="%23151522" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"%3E%3C/path%3E%3C/svg%3E'

  header?.addEventListener('click', () => {
    if (collapse.classList.contains('collapse_expand')) {
      header.style.backgroundColor = 'white'
      originalCloseBtn.style.display = 'block'
      imgCross.style.display = 'none'

      body.style.height = `${0}px`
      collapse.classList.remove('collapse_expand')
      return
    }

    header.style.backgroundColor = '#F0F0F0'
    originalCloseBtn.style.display = 'none'
    imgCross.style.display = 'block'

    body.style.height = `${bodyWrapperHeight}px`
    collapse.classList.add('collapse_expand')
  })

  originalCloseBtn?.insertAdjacentElement('beforebegin', imgCross)
  imgCross.style.display = 'none'
})
